import { Typography } from '@mui/material';
import { ClassApi } from 'api/class.api';
import { ReportsApi } from 'api/reports.api';
import { ButtonComp } from 'common/components/inputs/ButtonComp';
import { DatePickerInput } from 'common/components/inputs/DatePickerInput';
import { RegularInput } from 'common/components/inputs/RegularInput';
import { RegularSelect } from 'common/components/inputs/RegularSelect';
import dayjs from 'dayjs';
import { omitDeep } from 'library/helpers/helper';
import { CollectionClass } from 'library/types/Class';
import { CollectionApiResponse } from 'library/types/Common';
import {
  AmanatiMotive,
  CadruDisciplina,
  CatalogReportObjectJSON,
  RepetentiInfo,
  SituatieElevi,
  StudentCatalogWithMentions,
} from 'library/types/Reports';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CatalogMock from '../../resources/mockdata/catalogreport.json';
import { toast } from 'react-toastify';

export const ManageCatalogReport = (): JSX.Element => {
  const { t: tModules } = useTranslation('modules');
  const { t: tCommon } = useTranslation('common');

  const { getClass, addCatalogInfoToClass } = ClassApi();
  const { getCatalogReport } = ReportsApi();

  const [classes, setClasses] = useState<CollectionClass[]>([]);
  const [selectedClass, setSelectedClass] = useState(-1);

  const [selectedStep, setSelectedStep] = useState<number>(0);
  const [selectedSecretar, setSelectedSecretar] = useState<number>(-1);

  const [studentsCatalog, setStudentsCatalog] = useState<
    StudentCatalogWithMentions[]
  >([]);
  const [catalogReportJSONData, setCatalogReportJSONData] =
    useState<CatalogReportObjectJSON>({
      schoolName: '',
      schoolLocalitate: '',
      schoolJudet: '',
      codClasa: '',
      anInceput: '',
      anSfarsit: '',
      anStudiu: 0,
      director: '',
      cadruDidacticResponsabil: '',
      cadreSiDiscipline: [],
      denumireMinister: '',
      situatieElevi: {
        inscrisi: 0,
        veniti: 0,
        plecati: 0,
        existenti: 0,
        promovati: 0,
        corigentix1: 0,
        corigentix2: 0,
        repetenti: {
          count: 0,
          info: {
            1: 0,
            2: 0,
            3: 0,
            4: 0,
          },
        },
        amanati: {
          amanatix1: 0,
          amanatix2: 0,
          motive: {
            count: 0,
            info: {
              1: 0,
              2: 0,
              3: 0,
              4: 0,
            },
          },
        },
        absenteTotal: 0,
        absenteNemotivate: 0,
      },
      cadreSiDisciplineMedii: [],
      catalogInfo: null,
      secretariScoala: [],
      reportDate: dayjs(),
    });

  useEffect(() => {
    (async () => {
      const classes =
        (await getClass()) as CollectionApiResponse<CollectionClass>;
      setClasses(classes['hydra:member']);

      if (classes['hydra:member'].length === 1) {
        setSelectedClass(classes['hydra:member'][0].id);
      }
    })();
  }, []);

  useEffect(() => {
    if (selectedClass === -1) return;

    (async () => {
      const catalogReport = await getCatalogReport(selectedClass);
      // const catalogReport = CatalogMock;
      setStudentsCatalog(
        catalogReport.usersCatalog.map((e) => ({
          ...e,
          mentiuni: '',
        }))
      );
      if (catalogReport.catalogInfo) {
        const parsedCatalogReport = catalogReport.catalogInfo;
        const catalogReportAux = omitDeep(parsedCatalogReport, [
          'selectedSecretar',
        ]);
        setCatalogReportJSONData(catalogReportAux);
        if (parsedCatalogReport.secretariScoala.length) {
          if (parsedCatalogReport.selectedSecretar >= 0) {
            setSelectedSecretar(parsedCatalogReport.selectedSecretar);
          } else {
            setSelectedSecretar(0);
          }
        }
      } else {
        const catalogReportAux = omitDeep(catalogReport, [
          'usersCatalog',
          'catalogInfo',
        ]);
        setCatalogReportJSONData({
          ...catalogReportAux,
          catalogInfo: null,
          anInceput: dayjs(catalogReportAux.anInceput).year().toString(),
          anSfarsit: dayjs(catalogReportAux.anSfarsit).year().toString(),
          reportDate: dayjs(),
        });
        if (catalogReportAux.secretariScoala.length) {
          setSelectedSecretar(0);
        }
      }

      setSelectedStep(1);
    })();
  }, [selectedClass]);

  const handleCadruDisciplinaInputChange = (
    index: number,
    field: keyof CadruDisciplina,
    value: string,
    type: 'cadreSiDiscipline' | 'cadreSiDisciplineMedii'
  ) => {
    const updatedData = [...catalogReportJSONData[type]];
    updatedData[index][field] = value;
    setCatalogReportJSONData({
      ...catalogReportJSONData,
      [type]: updatedData,
    });
  };

  const handleSituatieEleviInputChange = (
    field: keyof SituatieElevi,
    value: string
  ) => {
    setCatalogReportJSONData({
      ...catalogReportJSONData,
      situatieElevi: {
        ...catalogReportJSONData.situatieElevi,
        [field]: parseInt(value) || 0,
      },
    });
  };

  const handleRepetentiInfoChange = (
    subfield: keyof RepetentiInfo['info'],
    value: string
  ) => {
    setCatalogReportJSONData({
      ...catalogReportJSONData,
      situatieElevi: {
        ...catalogReportJSONData.situatieElevi,
        repetenti: {
          ...catalogReportJSONData.situatieElevi.repetenti,
          info: {
            ...catalogReportJSONData.situatieElevi.repetenti.info,
            [subfield]: parseInt(value) || 0,
          },
        },
      },
    });
  };

  const handleAmanatiInfoChange = (
    subfield: keyof AmanatiMotive['info'],
    value: string
  ) => {
    setCatalogReportJSONData({
      ...catalogReportJSONData,
      situatieElevi: {
        ...catalogReportJSONData.situatieElevi,
        amanati: {
          ...catalogReportJSONData.situatieElevi.amanati,
          motive: {
            ...catalogReportJSONData.situatieElevi.amanati.motive,
            info: {
              ...catalogReportJSONData.situatieElevi.amanati.motive.info,
              [subfield]: parseInt(value) || 0,
            },
          },
        },
      },
    });
  };

  const handleStudentCatalogInputChange = (
    index: number,
    field: keyof StudentCatalogWithMentions,
    value: string
  ) => {
    const updatedStudentsCatalog = [...studentsCatalog];
    if (field === 'materiiInfo') {
      updatedStudentsCatalog[index][field] = JSON.parse(value);
    } else {
      updatedStudentsCatalog[index][field] = value as never;
    }
    setStudentsCatalog(updatedStudentsCatalog);
  };

  const saveValuesAndGenerate = async (shouldSave = true) => {
    if (selectedClass === -1 || !catalogReportJSONData) return;

    localStorage.setItem(
      'catalogReportJSONPrint',
      JSON.stringify({
        ...catalogReportJSONData,
        studentsCatalog,
        selectedSecretar:
          catalogReportJSONData.secretariScoala[selectedSecretar],
      })
    );

    try {
      if (shouldSave) {
        await addCatalogInfoToClass(selectedClass, {
          ...catalogReportJSONData,
          selectedSecretar,
        });

        setSelectedClass(-1);
        setSelectedSecretar(-1);
        setSelectedStep(0);
        setCatalogReportJSONData({
          schoolName: '',
          schoolLocalitate: '',
          schoolJudet: '',
          codClasa: '',
          anInceput: '',
          anSfarsit: '',
          anStudiu: 0,
          director: '',
          cadruDidacticResponsabil: '',
          cadreSiDiscipline: [],
          denumireMinister: '',
          situatieElevi: {
            inscrisi: 0,
            veniti: 0,
            plecati: 0,
            existenti: 0,
            promovati: 0,
            corigentix1: 0,
            corigentix2: 0,
            repetenti: {
              count: 0,
              info: {
                1: 0,
                2: 0,
                3: 0,
                4: 0,
              },
            },
            amanati: {
              amanatix1: 0,
              amanatix2: 0,
              motive: {
                count: 0,
                info: {
                  1: 0,
                  2: 0,
                  3: 0,
                  4: 0,
                },
              },
            },
            absenteTotal: 0,
            absenteNemotivate: 0,
          },
          cadreSiDisciplineMedii: [],
          catalogInfo: null,
          secretariScoala: [],
          reportDate: dayjs(),
        });
      }

      window.open('/catalog-report/print', '_blank');
    } catch (e) {
      console.log('err: ', e);
    }
  };

  const convertSituatieKeyToString = (key: string) => {
    switch (key) {
      case 'inscrisi':
        return 'Înscriși la începutul anului școlar';

      case 'veniti':
        return 'Veniţi în timpul anului şcolar';

      case 'plecati':
        return 'Plecaţi în timpul anului şcolar';

      case 'existenti':
        return 'Existenti la încheierea cursurilor';

      case 'promovati':
        return 'Promovaţi';

      case 'corigentix1':
        return 'Corigenţi - o disciplină/ un modul';

      case 'corigentix2':
        return 'Corigenţi - două discipline/ două module';

      case 'repetenti':
        return 'Repetenți';

      case 'repetenti info 1':
        return 'Repetenți - situația la învăţătură';

      case 'repetenti info 2':
        return 'Repetenți - motive medicale';

      case 'repetenti info 3':
        return 'Repetenți - abandon școlar';

      case 'repetenti info 4':
        return 'Repetenți din cauza altor situații';

      case 'amanati motive info 1':
        return 'Amânați - medical';

      case 'amanati motive info 2':
        return 'Amânați - abandon școlar';

      case 'amanati motive info 3':
        return 'Amânați - alte situații';

      case 'absenteTotal':
        return 'Absențe - Total';

      case 'absenteNemotivate':
        return 'Absențe - Nemotivate';
    }
  };

  return (
    <div className="page-container">
      <div className="flex items-center flex-col">
        {selectedStep === 0 && (
          <div
            style={{
              minWidth: '600px',
            }}
            className="select-student-step flex flex-col"
          >
            <Typography variant="h3" className="flex justify-center">
              {tModules('reports.catalogReport.selectClassStep')}
            </Typography>
            <RegularSelect
              options={classes.map((e) => ({
                value: e.id,
                label: e.codClasa,
              }))}
              onChange={(e) => {
                setSelectedClass(e);
              }}
              selectProps={{
                label: tModules('reports.catalogReport.selectedClass'),
                value: selectedClass,
                fullWidth: true,
                disabled: classes.length < 2,
              }}
            />
          </div>
        )}
        {selectedStep === 1 && catalogReportJSONData && (
          <div
            style={{
              minWidth: '600px',
            }}
            className="first-step flex flex-col"
          >
            {catalogReportJSONData.catalogInfo && (
              <div className="flex w-full justify-center gap-3">
                <ButtonComp
                  onButtonClick={() => saveValuesAndGenerate(false)}
                  buttonText={tCommon('inputs.step1GenerateCatalogueReport')}
                  buttonProps={{
                    variant: 'contained',
                  }}
                />
              </div>
            )}
            <div className="flex w-full justify-between gap-3">
              <ButtonComp
                onButtonClick={() => {
                  if (classes.length < 2)
                    return toast.error('Exista o singura clasa disponibila');
                  setSelectedClass(-1);
                  setSelectedStep(0);
                }}
                buttonText={tCommon('inputs.previousStep')}
                buttonProps={{
                  variant: 'contained',
                }}
              />
              <Typography variant="h3" className="flex justify-center">
                {tModules('reports.catalogReport.step1')}
              </Typography>
              <ButtonComp
                onButtonClick={() => {
                  setSelectedStep(2);
                }}
                buttonText={tCommon('inputs.nextStep')}
                buttonProps={{
                  variant: 'contained',
                }}
              />
            </div>
            <RegularInput
              textFieldProps={{
                fullWidth: true,
                label: tModules('reports.catalogReport.ministryName'),
                value: catalogReportJSONData.denumireMinister,
                onChange: (e) => {
                  setCatalogReportJSONData({
                    ...catalogReportJSONData,
                    denumireMinister: e.target.value,
                  });
                },
              }}
            />
            <RegularInput
              textFieldProps={{
                fullWidth: true,
                label: tModules('reports.catalogReport.schoolName'),
                value: catalogReportJSONData.schoolName,
                onChange: (e) => {
                  setCatalogReportJSONData({
                    ...catalogReportJSONData,
                    schoolName: e.target.value,
                  });
                },
              }}
            />
            <RegularInput
              textFieldProps={{
                fullWidth: true,
                label: tModules('reports.catalogReport.schoolLocalitate'),
                value: catalogReportJSONData.schoolLocalitate,
                onChange: (e) => {
                  setCatalogReportJSONData({
                    ...catalogReportJSONData,
                    schoolLocalitate: e.target.value,
                  });
                },
              }}
            />
            <RegularInput
              textFieldProps={{
                fullWidth: true,
                label: tModules('reports.catalogReport.schoolJudet'),
                value: catalogReportJSONData.schoolJudet,
                onChange: (e) => {
                  setCatalogReportJSONData({
                    ...catalogReportJSONData,
                    schoolJudet: e.target.value,
                  });
                },
              }}
            />
            <RegularInput
              textFieldProps={{
                fullWidth: true,
                label: tModules('reports.catalogReport.codClasa'),
                value: catalogReportJSONData.codClasa,
                onChange: (e) => {
                  setCatalogReportJSONData({
                    ...catalogReportJSONData,
                    codClasa: e.target.value,
                  });
                },
              }}
            />
            <RegularInput
              textFieldProps={{
                fullWidth: true,
                label: tModules('reports.catalogReport.anInceput'),
                value: catalogReportJSONData.anInceput,
                onChange: (e) => {
                  setCatalogReportJSONData({
                    ...catalogReportJSONData,
                    anInceput: e.target.value,
                  });
                },
              }}
            />
            <RegularInput
              textFieldProps={{
                fullWidth: true,
                label: tModules('reports.catalogReport.anSfarsit'),
                value: catalogReportJSONData.anSfarsit,
                onChange: (e) => {
                  setCatalogReportJSONData({
                    ...catalogReportJSONData,
                    anSfarsit: e.target.value,
                  });
                },
              }}
            />
            <RegularInput
              textFieldProps={{
                fullWidth: true,
                label: tModules('reports.catalogReport.anStudiu'),
                value: catalogReportJSONData.anStudiu,
                onChange: (e) => {
                  if (e.target.value === '' || /^[0-9]*$/.test(e.target.value))
                    setCatalogReportJSONData({
                      ...catalogReportJSONData,
                      anStudiu: parseInt(e.target.value),
                    });
                },
              }}
            />
            <RegularInput
              textFieldProps={{
                fullWidth: true,
                label: tModules('reports.catalogReport.director'),
                value: catalogReportJSONData.director,
                onChange: (e) => {
                  setCatalogReportJSONData({
                    ...catalogReportJSONData,
                    director: e.target.value,
                  });
                },
              }}
            />
            <RegularInput
              textFieldProps={{
                fullWidth: true,
                label: tModules(
                  'reports.catalogReport.cadruDidacticResponsabil'
                ),
                value: catalogReportJSONData.cadruDidacticResponsabil,
                onChange: (e) => {
                  setCatalogReportJSONData({
                    ...catalogReportJSONData,
                    cadruDidacticResponsabil: e.target.value,
                  });
                },
              }}
            />
            <RegularSelect
              options={catalogReportJSONData.secretariScoala.map((e, i) => ({
                value: i,
                label: e,
              }))}
              onChange={(e) => {
                setSelectedSecretar(e);
              }}
              selectProps={{
                label: tModules('reports.catalogReport.selectedSecretar'),
                value: selectedSecretar,
                fullWidth: true,
              }}
            />
            <DatePickerInput
              datePickerProps={{
                slotProps: {
                  textField: { fullWidth: true },
                },
                label: tModules('reports.catalogReport.date'),
                value: dayjs(catalogReportJSONData.reportDate),
                onChange: (e) => {
                  if (!e) return;
                  setCatalogReportJSONData({
                    ...catalogReportJSONData,
                    reportDate: e,
                  });
                },
              }}
            />
          </div>
        )}
        {selectedStep === 2 && catalogReportJSONData && (
          <div
            style={{
              minWidth: '600px',
            }}
            className="first-step flex flex-col"
          >
            <div className="flex w-full justify-between gap-3">
              <ButtonComp
                onButtonClick={() => {
                  setSelectedStep(1);
                }}
                buttonText={tCommon('inputs.previousStep')}
                buttonProps={{
                  variant: 'contained',
                }}
              />
              <Typography variant="h3" className="flex justify-center">
                {tModules('reports.catalogReport.step2')}
              </Typography>
              <ButtonComp
                onButtonClick={() => {
                  setSelectedStep(3);
                }}
                buttonText={tCommon('inputs.nextStep')}
                buttonProps={{
                  variant: 'contained',
                }}
              />
            </div>
            <div className="w-full">
              <Typography variant="h5" className="flex justify-center">
                Lista cadrelor didactice la începutul anului școlar
              </Typography>
            </div>
            <div className="container mx-auto">
              <table className="min-w-full table-auto bg-white rounded-lg overflow-hidden shadow-lg">
                <thead className="bg-gray-800 text-white">
                  <tr>
                    <th className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wider">
                      Cadru
                    </th>
                    <th className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wider">
                      Disciplină
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {catalogReportJSONData.cadreSiDiscipline.length &&
                    catalogReportJSONData.cadreSiDiscipline.map(
                      (item, index) => (
                        <tr key={index}>
                          <td className="px-3 py-3 whitespace-nowrap text-sm text-gray-900">
                            <input
                              type="text"
                              value={item.cadru}
                              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                handleCadruDisciplinaInputChange(
                                  index,
                                  'cadru',
                                  e.target.value,
                                  'cadreSiDiscipline'
                                )
                              }
                              className="w-full"
                            />
                          </td>
                          <td className="px-3 py-3 whitespace-nowrap text-sm text-gray-900">
                            <input
                              type="text"
                              value={item.disciplina}
                              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                handleCadruDisciplinaInputChange(
                                  index,
                                  'disciplina',
                                  e.target.value,
                                  'cadreSiDiscipline'
                                )
                              }
                              className="w-full"
                            />
                          </td>
                        </tr>
                      )
                    )}
                </tbody>
              </table>
            </div>
          </div>
        )}
        {selectedStep === 3 && catalogReportJSONData && (
          <div
            style={{
              minWidth: '600px',
            }}
            className="first-step flex flex-col items-center"
          >
            <div className="flex w-full justify-between gap-3">
              <ButtonComp
                onButtonClick={() => {
                  setSelectedStep(2);
                }}
                buttonText={tCommon('inputs.previousStep')}
                buttonProps={{
                  variant: 'contained',
                }}
              />
              <Typography variant="h3" className="flex justify-center">
                {tModules('reports.catalogReport.step3')}
              </Typography>
              <ButtonComp
                onButtonClick={() => {
                  setSelectedStep(4);
                }}
                buttonText={tCommon('inputs.nextStep')}
                buttonProps={{
                  variant: 'contained',
                }}
              />
            </div>
            <div className="w-2/3 justify-center">
              <Typography
                variant="h5"
                className="flex justify-center text-center"
              >
                Situația generală asupra mișcării și frecvenței elevilor și a
                rezultatelor obținute în timpul anului școlar
              </Typography>
            </div>
            <div className="container mx-auto">
              <table className="min-w-full table-auto bg-white rounded-lg overflow-hidden shadow-lg">
                <thead className="bg-gray-800 text-white">
                  <tr>
                    <th className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wider">
                      Câmp
                    </th>
                    <th className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wider">
                      Valoare
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {Object.keys(catalogReportJSONData.situatieElevi).map(
                    (key) => {
                      const field = key as keyof SituatieElevi;
                      if (
                        typeof catalogReportJSONData.situatieElevi[field] ===
                        'number'
                      ) {
                        return (
                          <tr key={key}>
                            <td className="px-3 py-3 whitespace-nowrap text-sm text-gray-900">
                              {convertSituatieKeyToString(key)}
                            </td>
                            <td className="px-3 py-3 whitespace-nowrap text-sm text-gray-900">
                              <input
                                type="number"
                                value={
                                  catalogReportJSONData.situatieElevi[
                                    field
                                  ] as number
                                }
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                  handleSituatieEleviInputChange(
                                    field,
                                    e.target.value
                                  )
                                }
                                className="w-full"
                              />
                            </td>
                          </tr>
                        );
                      }
                      if (field === 'repetenti') {
                        const repetentiInfo =
                          catalogReportJSONData.situatieElevi.repetenti.info;
                        return (
                          <React.Fragment key={key}>
                            <tr>
                              <td className="px-3 py-3 whitespace-nowrap text-sm text-gray-900">
                                Total repetenți
                              </td>
                              <td className="px-3 py-3 whitespace-nowrap text-sm text-gray-900">
                                <input
                                  type="number"
                                  value={
                                    catalogReportJSONData.situatieElevi
                                      .repetenti.count
                                  }
                                  onChange={(
                                    e: ChangeEvent<HTMLInputElement>
                                  ) =>
                                    handleSituatieEleviInputChange(
                                      'repetenti',
                                      e.target.value
                                    )
                                  }
                                  className="w-full"
                                />
                              </td>
                            </tr>
                            {Object.keys(repetentiInfo).map((subkey) => {
                              const subfield = parseInt(
                                subkey
                              ) as keyof RepetentiInfo['info'];
                              return (
                                <tr key={`${key}-${subkey}`}>
                                  <td className="px-3 py-3 whitespace-nowrap text-sm text-gray-900">
                                    {convertSituatieKeyToString(
                                      `repetenti info ${subkey}`
                                    )}
                                  </td>
                                  <td className="px-3 py-3 whitespace-nowrap text-sm text-gray-900">
                                    <input
                                      type="number"
                                      value={repetentiInfo[subfield]}
                                      onChange={(
                                        e: ChangeEvent<HTMLInputElement>
                                      ) =>
                                        handleRepetentiInfoChange(
                                          subfield,
                                          e.target.value
                                        )
                                      }
                                      className="w-full"
                                    />
                                  </td>
                                </tr>
                              );
                            })}
                          </React.Fragment>
                        );
                      }
                      if (field === 'amanati') {
                        const amanatiInfo =
                          catalogReportJSONData.situatieElevi.amanati.motive
                            .info;
                        return (
                          <React.Fragment key={key}>
                            <tr>
                              <td className="px-3 py-3 whitespace-nowrap text-sm text-gray-900">
                                Amânați la o disciplină / un modul
                              </td>
                              <td className="px-3 py-3 whitespace-nowrap text-sm text-gray-900">
                                <input
                                  type="number"
                                  value={
                                    catalogReportJSONData.situatieElevi.amanati
                                      .amanatix1
                                  }
                                  onChange={(
                                    e: ChangeEvent<HTMLInputElement>
                                  ) =>
                                    handleSituatieEleviInputChange(
                                      'amanati',
                                      e.target.value
                                    )
                                  }
                                  className="w-full"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="px-3 py-3 whitespace-nowrap text-sm text-gray-900">
                                Amânați la mai multe discipline / mai multe
                                module
                              </td>
                              <td className="px-3 py-3 whitespace-nowrap text-sm text-gray-900">
                                <input
                                  type="number"
                                  value={
                                    catalogReportJSONData.situatieElevi.amanati
                                      .amanatix2
                                  }
                                  onChange={(
                                    e: ChangeEvent<HTMLInputElement>
                                  ) =>
                                    handleSituatieEleviInputChange(
                                      'amanati',
                                      e.target.value
                                    )
                                  }
                                  className="w-full"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="px-3 py-3 whitespace-nowrap text-sm text-gray-900">
                                Amânați - total
                              </td>
                              <td className="px-3 py-3 whitespace-nowrap text-sm text-gray-900">
                                <input
                                  type="number"
                                  value={
                                    catalogReportJSONData.situatieElevi.amanati
                                      .motive.count
                                  }
                                  onChange={(
                                    e: ChangeEvent<HTMLInputElement>
                                  ) =>
                                    handleSituatieEleviInputChange(
                                      'amanati',
                                      e.target.value
                                    )
                                  }
                                  className="w-full"
                                />
                              </td>
                            </tr>
                            {Object.keys(amanatiInfo)
                              .slice(0, 3)
                              .map((subkey) => {
                                const subfield = parseInt(
                                  subkey
                                ) as keyof AmanatiMotive['info'];
                                return (
                                  <tr key={`${key}-${subkey}`}>
                                    <td className="px-3 py-3 whitespace-nowrap text-sm text-gray-900">
                                      {convertSituatieKeyToString(
                                        `amanati motive info ${subkey}`
                                      )}
                                    </td>
                                    <td className="px-3 py-3 whitespace-nowrap text-sm text-gray-900">
                                      <input
                                        type="number"
                                        value={amanatiInfo[subfield]}
                                        onChange={(
                                          e: ChangeEvent<HTMLInputElement>
                                        ) =>
                                          handleAmanatiInfoChange(
                                            subfield,
                                            e.target.value
                                          )
                                        }
                                        className="w-full"
                                      />
                                    </td>
                                  </tr>
                                );
                              })}
                          </React.Fragment>
                        );
                      }
                      return null;
                    }
                  )}
                </tbody>
              </table>
            </div>
          </div>
        )}
        {selectedStep === 4 && catalogReportJSONData && (
          <div
            style={{
              minWidth: '600px',
            }}
            className="first-step flex flex-col"
          >
            <div className="flex w-full justify-between gap-3">
              <ButtonComp
                onButtonClick={() => {
                  setSelectedStep(3);
                }}
                buttonText={tCommon('inputs.previousStep')}
                buttonProps={{
                  variant: 'contained',
                }}
              />
              <Typography variant="h3" className="flex justify-center">
                {tModules('reports.catalogReport.step4')}
              </Typography>
              <ButtonComp
                onButtonClick={() => {
                  setSelectedStep(5);
                }}
                buttonText={tCommon('inputs.nextStep')}
                buttonProps={{
                  variant: 'contained',
                }}
              />
            </div>
            <div className="justify-center">
              <Typography
                variant="h5"
                className="flex justify-center text-center"
              >
                DATE PERSONALE ALE ELEVILOR
              </Typography>
            </div>
            <div className="container mx-auto">
              <table className="min-w-full table-auto bg-white rounded-lg overflow-hidden shadow-lg">
                <thead className="bg-gray-800 text-white">
                  <tr>
                    <th
                      rowSpan={2}
                      className="px-2 py-3 text-center text-xs font-medium uppercase tracking-wider"
                    >
                      Nr. Crt.
                    </th>
                    <th
                      rowSpan={2}
                      className="px-2 py-3 text-left text-xs font-medium uppercase tracking-wider"
                    >
                      Nr. Volum
                    </th>
                    <th
                      rowSpan={2}
                      className="px-2 py-3 text-left text-xs font-medium uppercase tracking-wider"
                    >
                      Nr. Matricol
                    </th>
                    <th
                      rowSpan={2}
                      className="px-2 py-3 text-left text-xs font-medium uppercase tracking-wider"
                    >
                      Nr. Pagina
                    </th>
                    <th
                      rowSpan={2}
                      className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wider"
                    >
                      Nume si
                      <br />
                      prenume elev
                    </th>
                    <th
                      rowSpan={2}
                      className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wider"
                    >
                      Data nasterii
                    </th>
                    <th
                      colSpan={2}
                      className="px-3 py-3 text-center text-xs font-medium uppercase tracking-wider"
                    >
                      Prenumele Parintilor
                    </th>
                    <th
                      rowSpan={2}
                      className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wider"
                    >
                      Domiciliu si Telefon
                    </th>
                    <th
                      rowSpan={2}
                      className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wider"
                    >
                      Mentiuni
                    </th>
                  </tr>
                  <tr>
                    <th className="px-3 py-3 text-center text-xs font-medium uppercase tracking-wider">
                      Tata
                    </th>
                    <th className="px-3 py-3 text-center text-xs font-medium uppercase tracking-wider">
                      Mama
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {studentsCatalog.length &&
                    studentsCatalog.map((student, index) => (
                      <tr key={index}>
                        <td className="px-2 py-3 whitespace-nowrap text-sm text-gray-900 text-center">
                          {index + 1}
                        </td>
                        <td className="px-2 py-3 whitespace-nowrap text-sm text-gray-900">
                          <input
                            type="text"
                            value={student.volumRegistruMatricol || ''}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              handleStudentCatalogInputChange(
                                index,
                                'volumRegistruMatricol',
                                e.target.value
                              )
                            }
                            className="w-full"
                          />
                        </td>
                        <td className="px-2 py-3 whitespace-nowrap text-sm text-gray-900">
                          <input
                            type="text"
                            value={student.nrMatricol}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              handleStudentCatalogInputChange(
                                index,
                                'nrMatricol',
                                e.target.value
                              )
                            }
                            className="w-full"
                          />
                        </td>
                        <td className="px-2 py-3 whitespace-nowrap text-sm text-gray-900">
                          <input
                            type="text"
                            value={student.paginaRegistruMatricol || ''}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              handleStudentCatalogInputChange(
                                index,
                                'paginaRegistruMatricol',
                                e.target.value
                              )
                            }
                            className="w-full"
                          />
                        </td>
                        <td className="px-3 py-3 whitespace-nowrap text-sm text-gray-900">
                          <input
                            type="text"
                            value={student.numeComplet}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              handleStudentCatalogInputChange(
                                index,
                                'numeComplet',
                                e.target.value
                              )
                            }
                            className="w-full"
                          />
                        </td>
                        <td className="px-3 py-3 whitespace-nowrap text-sm text-gray-900">
                          <input
                            type="text"
                            value={student.dataNastere}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              handleStudentCatalogInputChange(
                                index,
                                'dataNastere',
                                e.target.value
                              )
                            }
                            className="w-full"
                          />
                        </td>
                        <td className="px-3 py-3 whitespace-nowrap text-sm text-gray-900 text-center">
                          <input
                            type="text"
                            value={student.prenumeTata || ''}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              handleStudentCatalogInputChange(
                                index,
                                'prenumeTata',
                                e.target.value
                              )
                            }
                            className="w-full"
                          />
                        </td>
                        <td className="px-3 py-3 whitespace-nowrap text-sm text-gray-900 text-center">
                          <input
                            type="text"
                            value={student.prenumeMama || ''}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              handleStudentCatalogInputChange(
                                index,
                                'prenumeMama',
                                e.target.value
                              )
                            }
                            className="w-full"
                          />
                        </td>
                        <td className="px-3 py-3 whitespace-nowrap text-sm text-gray-900">
                          <input
                            type="text"
                            value={student.domiciliuSiNumarTelefon}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              handleStudentCatalogInputChange(
                                index,
                                'domiciliuSiNumarTelefon',
                                e.target.value
                              )
                            }
                            className="w-full"
                          />
                        </td>
                        <td className="px-3 py-3 whitespace-nowrap text-sm text-gray-900">
                          <input
                            type="text"
                            value={student.mentiuni}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              handleStudentCatalogInputChange(
                                index,
                                'mentiuni',
                                e.target.value
                              )
                            }
                            className="w-full"
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
        {selectedStep === 5 && catalogReportJSONData && (
          <div
            style={{
              minWidth: '600px',
            }}
            className="first-step flex flex-col"
          >
            <div className="flex w-full justify-between gap-3">
              <ButtonComp
                onButtonClick={() => {
                  setSelectedStep(4);
                }}
                buttonText={tCommon('inputs.previousStep')}
                buttonProps={{
                  variant: 'contained',
                }}
              />
              <Typography variant="h3" className="flex justify-center">
                {tModules('reports.catalogReport.step5')}
              </Typography>
              <ButtonComp
                onButtonClick={saveValuesAndGenerate}
                buttonText={tCommon('inputs.generateCatalogueReport')}
                buttonProps={{
                  variant: 'contained',
                }}
              />
            </div>
            <div className="w-full">
              <Typography variant="h5" className="flex justify-center">
                Lista cadrelor didactice la sfârșitul anului școlar
              </Typography>
            </div>
            <div className="container mx-auto">
              <table className="min-w-full table-auto bg-white rounded-lg overflow-hidden shadow-lg">
                <thead className="bg-gray-800 text-white">
                  <tr>
                    <th className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wider">
                      Cadru
                    </th>
                    <th className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wider">
                      Disciplina
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {!!catalogReportJSONData.cadreSiDisciplineMedii.length &&
                    catalogReportJSONData.cadreSiDisciplineMedii.map(
                      (item, index) => (
                        <tr key={index}>
                          <td className="px-3 py-3 whitespace-nowrap text-sm text-gray-900">
                            <input
                              type="text"
                              value={item.cadru}
                              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                handleCadruDisciplinaInputChange(
                                  index,
                                  'cadru',
                                  e.target.value,
                                  'cadreSiDisciplineMedii'
                                )
                              }
                              className="w-full"
                            />
                          </td>
                          <td className="px-3 py-3 whitespace-nowrap text-sm text-gray-900">
                            <input
                              type="text"
                              value={item.disciplina}
                              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                handleCadruDisciplinaInputChange(
                                  index,
                                  'disciplina',
                                  e.target.value,
                                  'cadreSiDisciplineMedii'
                                )
                              }
                              className="w-full"
                            />
                          </td>
                        </tr>
                      )
                    )}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
